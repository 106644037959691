import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import googleButton from "../images/web_light_sq_SI@2x.png";

const LogInForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] =
    useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [resetMessage, setResetMessage] = useState("");
  const auth = getAuth();
  const navigate = useNavigate();
  const ERROR_MESSAGE = "There is a problem logging in";

  const handleLogIn = (e) => {
    e.preventDefault();

    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        navigate("/dashboard");
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      console.log("Google user signed in:", result.user);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error with Google sign-in:", error);
      setError("Google sign-in failed. Please try again.");
    }
  };

  const handleForgotPassword = () => {
    sendPasswordResetEmail(auth, resetEmail)
      .then(() => {
        setResetMessage("Password reset email sent. Please check your inbox.");
        setResetEmail("");
      })
      .catch((error) => {
        setResetMessage(`Error: ${error.message}`);
      });
  };

  const closeForgotPasswordModal = () => {
    setIsForgotPasswordModalOpen(false);
    setResetMessage("");
  };

  return (
    <div className="form-container">
      <form className="login-form" onSubmit={handleLogIn}>
        <div className="form-row">
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Email"
          />
        </div>
        <div className="form-row">
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Password"
          />
        </div>
        <div className="form-row">
          <button type="submit">Log In</button>
        </div>
        <div className="form-row">
          <button
            type="button"
            className="forgot-password-button"
            onClick={() => setIsForgotPasswordModalOpen(true)}
          >
            Forgot Password?
          </button>
        </div>
        <div className="form-row">
          <button onClick={handleGoogleSignIn} className="google-login-button">
            <img
              src={googleButton}
              alt="Sign in with Google"
              style={{
                width: "200px", // Adjust the width as needed
                height: "auto", // Keep the aspect ratio
                cursor: "pointer",
              }}
            />
          </button>
        </div>

        {error && <div className="error-message">{ERROR_MESSAGE}</div>}
      </form>

      {/* Forgot Password Modal */}
      {isForgotPasswordModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content-2">
            <h2>Reset Your Password</h2>
            <p>Enter your email address to receive a password reset link.</p>
            <input
              type="email"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
              placeholder="Email Address"
              required
            />
            <div className="modal-buttons">
              <button onClick={handleForgotPassword}>Send Reset Email</button>
              <button onClick={closeForgotPasswordModal} className="red-button">
                Cancel
              </button>
            </div>
            {resetMessage && <p className="reset-message">{resetMessage}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default LogInForm;
