import React, { useState, useContext } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { collection, doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./UserContext";
import { firestore } from "./FirebaseConfig";
import googleButton from "../images/web_light_sq_SU@2x.png";

const RegistrationForm = () => {
  const { setUid } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [title, setTitle] = useState("Ms.");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("Teacher");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const auth = getAuth();
      await createUserWithEmailAndPassword(auth, email, password);
      console.log("User registered successfully!");

      let displayName =
        title === "Other" ? `${firstName} ${lastName}` : `${title} ${lastName}`;
      const usersCollectionRef = collection(firestore, "users");
      const docRef = doc(usersCollectionRef, auth.currentUser.uid);

      await updateProfile(auth.currentUser, { displayName });

      await setDoc(docRef, {
        email,
        uid: auth.currentUser.uid,
        title,
        firstName,
        lastName,
        displayName,
        role,
      });

      const uid = auth.currentUser.uid;
      console.log("uid:", uid, "User was successfully added");

      navigate("/afterregistrationschoolselection");
    } catch (error) {
      console.error("Error registering user:", error);
      setError("Registration failed. Please try again.");
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);

      const user = result.user;
      const usersCollectionRef = collection(firestore, "users");
      const docRef = doc(usersCollectionRef, user.uid);

      // Extract firstName and lastName from displayName
      const displayName = user.displayName || "";
      const [firstName, ...lastNameParts] = displayName.split(" ");
      const lastName = lastNameParts.join(" ");

      // Save basic user info to Firestore
      await setDoc(docRef, {
        email: user.email,
        uid: user.uid,
        displayName,
        firstName: firstName || "",
        lastName: lastName || "",
        role: "Teacher", // Default role
        title: "", // Will be filled later
        schoolId: "", // Will be filled later
        schoolName: "", // Will be filled later
      });

      console.log("Google user signed in and basic data saved:", user);

      // Redirect user to a profile completion page
      navigate("/completeprofile");
    } catch (error) {
      console.error("Error with Google sign-in:", error);
      setError("Google sign-in failed. Please try again.");
    }
  };

  return (
    <div className="form-container">
      <form className="registration-form" onSubmit={handleSubmit}>
        {/* Registration form fields */}
        <div className="form-row">
          <select
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          >
            <option value="" disabled>
              Select Title
            </option>
            <option value="Ms.">Ms.</option>
            <option value="Miss">Miss</option>
            <option value="Mrs.">Mrs.</option>
            <option value="Mr.">Mr.</option>
            <option value="Dr.">Dr.</option>
            <option value="Prof.">Prof.</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="form-row">
          <input
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
            placeholder="First Name"
          />
        </div>
        <div className="form-row">
          <input
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
            placeholder="Last Name"
          />
        </div>
        <div className="form-row">
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Email"
          />
        </div>
        <div className="form-row">
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Password"
          />
        </div>
        <div className="form-row">
          <select
            id="role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            required
          >
            <option value="" disabled>
              Select Role
            </option>
            <option value="Teacher">Teacher</option>
          </select>
        </div>
        <div className="form-row">
          <button type="submit">Register</button>
        </div>
      </form>
      <div className="form-row">
        <button onClick={handleGoogleSignIn} className="google-login-button">
          <img
            src={googleButton}
            alt="Sign in with Google"
            style={{
              width: "200px", // Adjust the width as needed
              height: "auto", // Keep the aspect ratio
              cursor: "pointer",
            }}
          />
        </button>
      </div>

      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default RegistrationForm;
