import React, { useState, useRef, useEffect } from "react";
import {
  doc,
  collection,
  getDocs,
  query,
  where,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { firestore, serverTimestamp } from "./FirebaseConfig";
import useFetchClasses from "./useFetchClasses";
import useFilteredStudents from "./useFilteredStudents";
import "./Attendance.css";

const Attendance = () => {
  const [selectedClassId, setSelectedClassId] = useState("");
  const [studentIdInput, setStudentIdInput] = useState("");
  const [showCheckedInModal, setShowCheckedInModal] = useState(false);
  const { classList } = useFetchClasses();
  const { notPresentStudents, presentStudents, refreshFilteredStudents } =
    useFilteredStudents(selectedClassId);
  const studentIdInputRef = useRef();

  // Automatically select the first class in the list on load
  useEffect(() => {
    if (!selectedClassId && classList.length > 0) {
      setSelectedClassId(classList[0].id); // Automatically select the first class
    }
  }, [classList, selectedClassId]);

  const handleClassChange = (event) => {
    const selectedId = event.target.value;
    setSelectedClassId(selectedId);
  };

  const markAttendance = async (sid, isPresent) => {
    if (!selectedClassId) return;

    try {
      const classRef = doc(firestore, "classes", selectedClassId);
      const attendanceCollectionRef = collection(classRef, "attendance");
      const currentDate = new Date().toISOString().slice(0, 10);
      const attendanceDocRef = doc(attendanceCollectionRef, currentDate);

      const studentAttendanceCollectionRef = collection(
        attendanceDocRef,
        "students"
      );
      const studentAttendanceDocRef = doc(studentAttendanceCollectionRef, sid);

      if (isPresent) {
        await deleteDoc(studentAttendanceDocRef);
      } else {
        await setDoc(studentAttendanceDocRef, { timestamp: serverTimestamp() });
      }

      refreshFilteredStudents();
    } catch (error) {
      console.error("Error marking attendance:", error);
    }
  };

  const handleStudentIdInput = (event) => setStudentIdInput(event.target.value);

  const handleMarkByStudentId = async () => {
    if (!selectedClassId) return;

    try {
      const studentsCollectionRef = collection(firestore, "students");
      const q = query(
        studentsCollectionRef,
        where("studentId", "==", studentIdInput.trim())
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.warn("Student not found for ID:", studentIdInput);
        return;
      }

      const studentDoc = querySnapshot.docs[0];
      const studentData = studentDoc.data();
      const sid = studentDoc.id;

      if (!studentData.studentName) {
        console.warn("Missing studentName field for student:", studentData);
      }

      markAttendance(sid, false);
      setStudentIdInput("");
      studentIdInputRef.current.focus();
    } catch (error) {
      console.error("Error marking attendance by ID:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") handleMarkByStudentId();
  };

  const formatTimestamp = (timestamp) => {
    const options = { hour: "numeric", minute: "numeric" };
    return new Date(timestamp).toLocaleString(undefined, options);
  };

  const sortStudentsByLastName = (students) => {
    return [...students].sort((a, b) => {
      const lastNameA =
        a.studentName?.split(" ").slice(-1)[0]?.toLowerCase() || "";
      const lastNameB =
        b.studentName?.split(" ").slice(-1)[0]?.toLowerCase() || "";
      return lastNameA.localeCompare(lastNameB);
    });
  };

  const sortedNotPresentStudents = sortStudentsByLastName(notPresentStudents);
  const sortedPresentStudents = sortStudentsByLastName(presentStudents);

  return (
    <div className="attendance-container">
      <h2>Mark Attendance for Today</h2>

      {/* Class Selection */}
      <div>
        <label>Class:</label>
        {classList.length > 1 ? (
          <select
            className="class-select"
            value={selectedClassId}
            onChange={handleClassChange}
          >
            {classList.map((cls) => (
              <option key={cls.id} value={cls.id}>
                {cls.className}
              </option>
            ))}
          </select>
        ) : (
          <span>{classList[0]?.className || "No classes available"}</span>
        )}
      </div>

      {/* Not Present Students */}
      <div className="student-grid">
        {sortedNotPresentStudents.map((student) => (
          <div
            key={student.id}
            className="student-item not-present"
            onClick={() => markAttendance(student.id, false)}
          >
            <span className="student-name">
              {student.studentName || "Unnamed Student"}
            </span>
          </div>
        ))}
      </div>

      {/* Modal for Checked-In Students */}
      {showCheckedInModal && (
        <div className="modal-overlay">
          <div className="modal-content-2">
            <h2>Checked-In Students</h2>
            <div className="modal-grid">
              {sortedPresentStudents.map((student) => (
                <div
                  key={student.id}
                  className="modal-student-item"
                  onClick={() => markAttendance(student.id, true)}
                >
                  <span>{student.studentName}</span>
                  <span>{formatTimestamp(student.timestamp)}</span>
                </div>
              ))}
            </div>
            <div className="modal-buttons">
              <button
                className="blue-buttons"
                onClick={() => setShowCheckedInModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      <button
        className="blue-buttons"
        onClick={() => setShowCheckedInModal(true)}
        style={{ margin: "20px" }}
      >
        View Checked-In Students
      </button>
    </div>
  );
};

export default Attendance;
