// PublicSignOutPage.js
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const PublicSignOutPage = () => {
  const { id } = useParams();
  const [sessionData, setSessionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    // Fetch session data using the unique ID
    fetch(`/api/session/${id}`)
      .then((res) => {
        if (!res.ok) throw new Error("Session not found");
        return res.json();
      })
      .then((data) => {
        setSessionData(data);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Session not found or invalid link.</p>;

  return (
    <div>
      <h1>Sign Out for {sessionData.className}</h1>
      <ul>
        {sessionData.students.map((student) => (
          <li key={student.id}>
            {student.name}
            <button onClick={() => handleSignOut(student.id)}>Sign Out</button>
          </li>
        ))}
      </ul>
    </div>
  );

  function handleSignOut(studentId) {
    fetch(`/api/session/${id}/signout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ studentId }),
    })
      .then((res) => {
        if (res.ok) {
          alert("Signed out successfully!");
        } else {
          alert("Failed to sign out.");
        }
      })
      .catch(() => alert("Error occurred during sign-out."));
  }
};

export default PublicSignOutPage;
