import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "./FirebaseConfig";

const SchoolDropdown = ({ onAddSchool, onSelectSchool }) => {
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchSchools();
  }, []);

  const fetchSchools = async () => {
    const schoolsCollectionRef = collection(firestore, "schools");
    const querySnapshot = await getDocs(schoolsCollectionRef);
    const schoolList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      schoolName: doc.data().schoolName,
      address: doc.data().address,
      city: doc.data().city,
      state: doc.data().state,
      zipcode: doc.data().zipcode, // Keep consistent field names
    }));
    setSchools(schoolList);
  };

  const handleSchoolChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedSchool(selectedValue);

    if (selectedValue === "addSchool") {
      onAddSchool();
      return;
    }

    const selectedSchoolObject = schools.find(
      (school) => school.id === selectedValue
    );

    if (selectedSchoolObject) {
      onSelectSchool(selectedSchoolObject); // Pass the entire school object
    } else {
      onSelectSchool(null);
    }
  };

  const filteredSchools = schools.filter((school) =>
    school.schoolName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <select
        value={selectedSchool}
        onChange={handleSchoolChange}
        style={{ width: "50%" }}
      >
        <option value="">Select a School</option>
        {filteredSchools.map((school) => (
          <option key={school.id} value={school.id}>
            {school.schoolName} - {school.address}, {school.city},{" "}
            {school.state}, {school.zipcode}
          </option>
        ))}
        <option value="addSchool">Add School</option>
      </select>
    </div>
  );
};

export default SchoolDropdown;
