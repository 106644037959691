import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  Outlet,
} from "react-router-dom";

import { UserProvider } from "./components/UserContext";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// Components
import NavBar from "./components/NavBar";
import LogInForm from "./components/LogInForm";
import RegistrationForm from "./components/RegistrationForm";
import Dashboard from "./components/Dashboard";
import LeftSidePanel from "./components/LeftSidePanel";
import Home from "./components/Home";
import About from "./components/About";
import Schools from "./components/Schools";
import Teachers from "./components/Teachers";
import ClassSignOutPage from "./components/ClassSignOutPage";
import PublicSignOutPage from "./components/PublicSignOutPage";
import AddClass from "./components/AddClass";
import ClassDetails from "./components/ClassDetails";
import DisplayClasses from "./components/DisplayClasses";
import StudentsPage from "./components/StudentsPage";
import AddStudent from "./components/AddStudent";
import DisplayEnrolledStudents from "./components/DisplayEnrolledStudents";
import Attendance from "./components/Attendance";
import AttendanceRecord from "./components/AttendanceRecord";
import SignOutsTeacherRecord from "./components/SignOutsTeacherRecord";
import Emergency from "./components/Emergency";
import Invitations from "./components/Invitations";
import SchoolList from "./components/SchoolList";
import SchoolListWithEmergencies from "./components/SchoolListWithEmergencies";
import AfterRegistrationSchoolSelection from "./components/AfterRegistrationSchoolSelection";
import ArchivedClasses from "./components/ArchivedClasses";
import Settings from "./components/Settings";
import AdminPanel from "./components/AdminPanel";
import ErrorBoundary from "./components/ErrorBoundary";
import AttendanceViewer from "./components/AttendanceViewer";
import CompleteProfile from "./components/CompleteProfile";
import StudentLogIn from "./components/StudentLogIn"; // Student Login
import StudentNavBar from "./components/StudentNavBar"; // Student Navigation Bar
import StudentDashboard from "./components/StudentDashboard";

import "./App.css";

function App() {
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  // Detect if we are on students.classboon.com
  const isStudentsSubdomain = window.location.hostname.startsWith("students");

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUserLoggedIn(!!user);
    });
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <UserProvider>
        <div className="app-container">
          {/* Show the correct navbar */}
          {!isStudentsSubdomain && <NavBar />}
          <ErrorBoundary>
            <Routes>
              {/* Student-Specific Layout */}
              {isStudentsSubdomain ? (
                <Route element={<StudentLayout />}>
                  <Route path="/" element={<StudentLogIn />} />
                  <Route path="/student-login" element={<StudentLogIn />} />
                  <Route
                    path="/student-dashboard"
                    element={
                      <>
                        <StudentNavBar />
                        <StudentDashboard />
                      </>
                    }
                  />

                  <Route path="*" element={<Navigate to="/" replace />} />
                </Route>
              ) : (
                <>
                  {/* Authenticated Routes */}
                  {userLoggedIn ? (
                    <>
                      {/* Routes with LeftSidePanel */}
                      <Route element={<AuthenticatedLayout />}>
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route
                          path="/displayclasses"
                          element={<DisplayClasses />}
                        />
                        <Route path="/invitations" element={<Invitations />} />
                        <Route
                          path="/displayenrolledstudents"
                          element={<DisplayEnrolledStudents />}
                        />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/addclass" element={<AddClass />} />
                        <Route
                          path="/signoutsteacherrecord"
                          element={<SignOutsTeacherRecord />}
                        />
                        <Route
                          path="/addstudent/:classId"
                          element={<AddStudent />}
                        />
                        <Route
                          path="/archivedclasses"
                          element={<ArchivedClasses />}
                        />
                        <Route
                          path="/attendanceviewer"
                          element={<AttendanceViewer />}
                        />
                      </Route>

                      {/* Routes without LeftSidePanel */}
                      <Route path="/attendance" element={<Attendance />} />
                      <Route
                        path="/attendancerecord"
                        element={<AttendanceRecord />}
                      />
                      <Route path="/emergency" element={<Emergency />} />
                      <Route
                        path="/classsignoutpage"
                        element={<ClassSignOutPage />}
                      />
                      <Route path="/teachers" element={<Teachers />} />
                      <Route path="/schools" element={<Schools />} />
                      <Route path="/schoollist" element={<SchoolList />} />
                      <Route
                        path="/schoollistwithemergencies"
                        element={<SchoolListWithEmergencies />}
                      />
                      <Route path="/classdetails" element={<ClassDetails />} />
                      <Route path="/addclass" element={<AddClass />} />
                    </>
                  ) : (
                    <Route
                      path="*"
                      element={<Navigate to="/loginform" replace />}
                    />
                  )}

                  {/* Public Routes */}
                  <Route path="/" element={<Home />} />
                  <Route path="/adminpanel" element={<AdminPanel />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/register" element={<RegistrationForm />} />
                  <Route path="/loginform" element={<LogInForm />} />
                  <Route
                    path="/afterregistrationschoolselection"
                    element={<AfterRegistrationSchoolSelection />}
                  />
                  <Route path="/signout/:id" element={<PublicSignOutPage />} />
                  <Route
                    path="/completeprofile"
                    element={<CompleteProfile />}
                  />

                  {/* Catch-All Route */}
                  <Route path="*" element={<h1>404 Not Found</h1>} />
                </>
              )}
            </Routes>
          </ErrorBoundary>
        </div>
      </UserProvider>
    </Router>
  );
}

// Student Layout with StudentNavBar
const StudentLayout = () => {
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) navigate("/student-login");
    });
    return () => unsubscribe();
  }, [auth, navigate]);

  return (
    <div className="student-layout">
      <StudentNavBar />
      <div className="student-content">
        <Outlet />
      </div>
    </div>
  );
};

// Authenticated Layout with LeftSidePanel
const AuthenticatedLayout = () => {
  return (
    <div className="authenticated-layout">
      <LeftSidePanel />
      <div className="authenticated-content">
        <Outlet />
      </div>
    </div>
  );
};

export default App;
