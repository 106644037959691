import React from "react";
import {
  doc,
  setDoc,
  deleteDoc,
  getDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { firestore, auth } from "./FirebaseConfig";

const SignInButton = ({ id, classId, studentName, idNumber }) => {
  const handleSignIn = async () => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        console.error("User is not authenticated.");
        alert("You must be logged in to sign in a student.");
        return;
      }

      const enteredId = prompt(
        `Enter ID for ${studentName} or use a master code:`
      );
      if (!enteredId) {
        console.log("ID prompt canceled.");
        return;
      }

      if (enteredId === "9876") {
        await signInAllStudents();
        return;
      }

      if (enteredId !== idNumber && enteredId !== "1234") {
        alert("Invalid ID number.");
        return;
      }

      await signInStudent(currentUser.uid, id, classId, studentName);
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  const signInStudent = async (uid, studentId, classId, studentName) => {
    try {
      const classSignOutRef = doc(
        firestore,
        `classes/${classId}/signouts/${studentId}`
      );
      const signOutDataSnapshot = await getDoc(classSignOutRef);

      if (!signOutDataSnapshot.exists()) {
        console.error("Sign-out record not found.");
        alert(`Sign-out record not found for ${studentName}.`);
        return;
      }

      const signOutData = signOutDataSnapshot.data();

      // Add a sign-in record under the class's signed-in history
      const classHistoryRef = doc(
        firestore,
        `classes/${classId}/signedInHistory/${studentId}`
      );
      await setDoc(classHistoryRef, {
        ...signOutData,
        time_in: new Date().toISOString(),
      });

      // Remove the sign-out record from the class collection
      await deleteDoc(classSignOutRef);

      console.log(`${studentName} signed in successfully!`);
    } catch (error) {
      console.error(`Error signing in ${studentName}:`, error);
    }
  };

  const signInAllStudents = async () => {
    try {
      console.log("Signing in all students...");

      // Get all classes
      const classesSnapshot = await getDocs(collection(firestore, "classes"));
      if (classesSnapshot.empty) {
        console.log("No classes found.");
        return;
      }

      const signInPromises = [];

      // Loop through each class and sign in all students currently signed out
      classesSnapshot.forEach(async (classDoc) => {
        const classId = classDoc.id;
        const signoutsSnapshot = await getDocs(
          collection(firestore, `classes/${classId}/signouts`)
        );

        if (!signoutsSnapshot.empty) {
          signoutsSnapshot.forEach((signoutDoc) => {
            const signOutData = signoutDoc.data();
            const studentId = signoutDoc.id;

            // Create a sign-in record
            const classHistoryRef = doc(
              firestore,
              `classes/${classId}/signedInHistory/${studentId}`
            );

            // Add to batch of sign-in tasks
            signInPromises.push(
              setDoc(classHistoryRef, {
                ...signOutData,
                time_in: new Date().toISOString(),
              }).then(() => deleteDoc(signoutDoc.ref))
            );
          });
        }
      });

      // Wait for all sign-in operations to complete
      await Promise.all(signInPromises);

      console.log("All students signed in successfully!");
      alert("All students signed in successfully!");
    } catch (error) {
      console.error("Error signing in all students:", error);
    }
  };

  return <button onClick={handleSignIn}>Sign In</button>;
};

export default SignInButton;
