// StudentClassEnroll.js
import React, { useState } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  arrayUnion,
  doc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { firestore } from "./FirebaseConfig";

const StudentClassEnroll = ({ onClassJoined }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [enrollmentCode, setEnrollmentCode] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const auth = getAuth();

  const handleEnroll = async () => {
    try {
      const classQuerySnapshot = await getDocs(
        query(
          collection(firestore, "classes"),
          where("enrollmentCode", "==", enrollmentCode)
        )
      );

      if (!classQuerySnapshot.empty) {
        const classDoc = classQuerySnapshot.docs[0];
        const classData = classDoc.data();

        console.log("Class data retrieved:", classData); // Log for debugging

        // Add student to class's enrolled students
        await updateDoc(classDoc.ref, {
          enrolledStudents: arrayUnion(auth.currentUser.uid),
        });

        // Add class to student's enrolled classes and update with teacherId
        const studentDocRef = doc(firestore, "students", auth.currentUser.uid);
        await updateDoc(studentDocRef, {
          enrolledClasses: arrayUnion(classDoc.id),
          teachers: arrayUnion(classData.teacherId), // Corrected field reference
          schoolId: classData.schoolId || "",
        });

        setSuccessMessage("Successfully enrolled!");
        setErrorMessage("");
        setEnrollmentCode("");
        setModalOpen(false);
        onClassJoined();
      } else {
        setErrorMessage("Invalid enrollment code. Please try again.");
      }
    } catch (error) {
      console.error("Error enrolling:", error);
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <button onClick={() => setModalOpen(true)}>Join Class</button>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2 className="modal-title">Enter Join Code</h2>
            <input
              type="text"
              value={enrollmentCode}
              onChange={(e) => setEnrollmentCode(e.target.value)}
              placeholder="Join Code"
              className="modal-input"
            />
            {successMessage && (
              <p className="success-message">{successMessage}</p>
            )}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <div className="modal-buttons">
              <button
                className="modal-close-button"
                onClick={() => setModalOpen(false)}
              >
                Close
              </button>
              <button className="modal-join-button" onClick={handleEnroll}>
                Join
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StudentClassEnroll;
