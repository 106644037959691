import React, { useEffect, useState, useContext } from "react";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import SignInButton from "./SignInButton";
import { firestore } from "./FirebaseConfig";
import { UserContext } from "./UserContext";
import "./DisplaySignOut.css";

function formatTime(timestamp) {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes.toString().padStart(2, "0");
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
}

const DisplaySignOut = () => {
  const [signOutData, setSignOutData] = useState([]);
  const { user } = useContext(UserContext);
  const uid = user?.uid;

  useEffect(() => {
    if (!uid) return;

    const fetchSignOutsFromClasses = () => {
      const classesQuery = query(
        collection(firestore, "classes"),
        where("teacherId", "==", uid)
      );

      const unsubscribe = onSnapshot(classesQuery, (snapshot) => {
        const unsubscribeSignOuts = [];

        const classDataList = snapshot.docs.map((classDoc) => {
          const classData = classDoc.data();
          const classId = classDoc.id;

          // Listen for changes in the signouts collection for each class
          const signoutsRef = collection(
            firestore,
            `classes/${classId}/signouts`
          );
          const signoutsUnsubscribe = onSnapshot(
            signoutsRef,
            (signoutSnapshot) => {
              const signouts = signoutSnapshot.docs.map((signoutDoc) => ({
                id: signoutDoc.id,
                classId,
                className: classData.className,
                ...signoutDoc.data(),
              }));

              setSignOutData((prevData) => {
                // Replace data for the current class in state
                return [
                  ...prevData.filter((item) => item.classId !== classId),
                  ...signouts,
                ];
              });
            }
          );

          unsubscribeSignOuts.push(signoutsUnsubscribe);
        });

        return () => unsubscribeSignOuts.forEach((unsub) => unsub());
      });

      return unsubscribe;
    };

    const unsubscribe = fetchSignOutsFromClasses();

    return () => unsubscribe && unsubscribe();
  }, [uid]);

  return (
    <div className="display-sign-out-container">
      <h2>Active Sign-Outs</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Location</th>
            <th>Time Out</th>
            <th>Class</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {signOutData.length === 0 ? (
            <tr>
              <td colSpan="5" className="no-data">
                No active sign-outs at the moment.
              </td>
            </tr>
          ) : (
            signOutData.map((signOut) => (
              <tr key={signOut.id}>
                <td>{signOut.studentName}</td>
                <td>{signOut.location}</td>
                <td>{formatTime(signOut.time_out)}</td>
                <td>{signOut.className}</td>
                <td>
                  <SignInButton
                    id={signOut.id}
                    classId={signOut.classId}
                    studentName={signOut.studentName}
                    idNumber={signOut.idNumber}
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DisplaySignOut;
