// StudentNavBar.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import StudentClassEnroll from "./StudentClassEnroll";
import "./StudentNavBar.css";

const StudentNavBar = ({ onClassJoined }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user);
    });
    return () => unsubscribe();
  }, [auth]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/student-login");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <nav className="student-navbar">
      <div className="student-navbar-container">
        <div className="student-logo">
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/258d6d1aee6af2a890868e69b1426fbf40bcfa0a1d0cfeb6f306ff216535e9b4?"
            alt="Logo"
            className="student-logo-img"
          />
        </div>
        {isLoggedIn && (
          <div className="navbar-buttons">
            <StudentClassEnroll onClassJoined={onClassJoined} />
            <button className="student-logout-button" onClick={handleLogout}>
              Logout
            </button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default StudentNavBar;
