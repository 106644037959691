import React, { useState, useEffect } from "react";
import AddStudentSingle from "./AddStudentSingle";
import AddStudentList from "./AddStudentList";
import AddStudentFile from "./AddStudentFile";
import DisplayEnrolledStudentsTable from "./DisplayEnrolledStudentsTable";
import Modal from "./Modal"; // Import the Modal component
import useFetchClasses from "./useFetchClasses";
import { useParams } from "react-router-dom";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { firestore } from "./FirebaseConfig";
import "./AddStudent.css";

const AddStudent = () => {
  const { classList } = useFetchClasses();
  const { classId } = useParams();
  const [isSingleModalOpen, setIsSingleModalOpen] = useState(false);
  const [isListModalOpen, setIsListModalOpen] = useState(false);
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [enrollmentCode, setEnrollmentCode] = useState("");

  // Function to refresh the student table after adding students
  const refreshStudentTable = () => {
    setTimeout(() => {
      setRefreshKey((prevKey) => prevKey + 1);
    }, 1000); // 1-second delay
  };

  // Fetch existing enrollment code when the component loads
  useEffect(() => {
    fetchEnrollmentCode();
  }, [classId]);

  const fetchEnrollmentCode = async () => {
    if (!classId) return;

    try {
      const classRef = doc(firestore, "classes", classId);
      const classDoc = await getDoc(classRef);

      if (classDoc.exists() && classDoc.data().enrollmentCode) {
        setEnrollmentCode(classDoc.data().enrollmentCode); // Display existing code
      } else {
        setEnrollmentCode(""); // No existing code, keep it empty
      }
    } catch (error) {
      console.error("Error fetching enrollment code:", error);
    }
  };

  // Function to generate a new enrollment code
  const generateEnrollmentCode = async () => {
    if (!classId) return;

    try {
      const classRef = doc(firestore, "classes", classId);

      // Generate a random 6-character code
      const newCode = Math.random().toString(36).substring(2, 8).toUpperCase();

      // Update Firestore with the new code
      await updateDoc(classRef, { enrollmentCode: newCode });

      // Update local state immediately
      setEnrollmentCode(newCode);
    } catch (error) {
      console.error("Error generating enrollment code:", error);
    }
  };

  return (
    <div className="add-student-form-container">
      <h1>Add Students</h1>
      <div className="import-method-buttons">
        <button onClick={() => setIsSingleModalOpen(true)}>
          Add Single Student
        </button>
        <button onClick={() => setIsListModalOpen(true)}>
          Add List of Students
        </button>
        <button onClick={() => setIsFileModalOpen(true)}>
          Import from File
        </button>
      </div>

      <div className="enrollment-code-section">
        <button onClick={generateEnrollmentCode}>
          {enrollmentCode ? "Refresh Join Code" : "Generate Enrollment Code"}
        </button>
        {enrollmentCode && (
          <p>
            Share this code with students to enroll in this class:{" "}
            <strong>{enrollmentCode}</strong>
          </p>
        )}
      </div>

      {/* Modal for adding a single student */}
      <Modal
        isOpen={isSingleModalOpen}
        onClose={() => setIsSingleModalOpen(false)}
      >
        <AddStudentSingle
          classList={classList}
          classId={classId}
          onStudentAdded={() => {
            refreshStudentTable();
            setIsSingleModalOpen(false);
          }}
        />
      </Modal>

      {/* Modal for adding a list of students */}
      <Modal isOpen={isListModalOpen} onClose={() => setIsListModalOpen(false)}>
        <AddStudentList
          classList={classList}
          classId={classId}
          onStudentsAdded={() => {
            refreshStudentTable();
            setIsListModalOpen(false);
          }}
        />
      </Modal>

      {/* Modal for importing students from a file */}
      <Modal isOpen={isFileModalOpen} onClose={() => setIsFileModalOpen(false)}>
        <AddStudentFile
          classList={classList}
          classId={classId}
          onStudentsAdded={() => {
            refreshStudentTable();
            setIsFileModalOpen(false);
          }}
          onComplete={() => setIsFileModalOpen(false)}
        />
      </Modal>

      {/* Display the table of enrolled students */}
      <DisplayEnrolledStudentsTable
        classList={classList}
        selectedClass={classId}
        key={refreshKey}
      />
    </div>
  );
};

export default AddStudent;
