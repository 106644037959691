import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { firestore } from "./FirebaseConfig";
import googleButton from "../images/web_light_sq_SI@2x.png";

const StudentLogIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] =
    useState(false);
  const [isStudentInfoModalOpen, setIsStudentInfoModalOpen] = useState(false);
  const [studentDetails, setStudentDetails] = useState({
    firstName: "",
    lastName: "",
    grade: "",
    studentId: "",
  });

  const auth = getAuth();
  const navigate = useNavigate();
  const ERROR_MESSAGE = "There is a problem logging in";

  const handleLogInOrRegister = async (e) => {
    e.preventDefault();

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      console.log("Student logged in:", userCredential.user);
      navigate("/student-dashboard");
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        await handleRegister();
      } else {
        console.error("Error during login:", error);
        setError(ERROR_MESSAGE);
      }
    }
  };

  const handleRegister = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const student = userCredential.user;

      // Save basic data to Firestore with empty placeholders
      const studentDocRef = doc(firestore, "students", student.uid);
      await setDoc(
        studentDocRef,
        {
          email: student.email,
          uid: student.uid,
          firstName: "",
          lastName: "",
          studentName: "",
          grade: "",
          studentId: "",
          enrolledClasses: [],
          addedBy: "unknown",
        },
        { merge: true }
      );

      // Open the modal to complete the registration process
      console.log("Opening student info modal...");
      setIsStudentInfoModalOpen(true);
    } catch (error) {
      console.error("Error registering student:", error);
      setError(ERROR_MESSAGE);
    }
  };

  const closeStudentInfoModal = async () => {
    try {
      const studentName =
        `${studentDetails.firstName.trim()} ${studentDetails.lastName.trim()}`.trim();

      // Update both Firebase Auth and Firestore with completed details
      await updateProfile(auth.currentUser, { displayName: studentName });

      const studentDocRef = doc(firestore, "students", auth.currentUser.uid);
      await setDoc(
        studentDocRef,
        {
          firstName: studentDetails.firstName,
          lastName: studentDetails.lastName,
          studentName: studentName,
          grade: studentDetails.grade,
          studentId: studentDetails.studentId,
        },
        { merge: true }
      );

      console.log("Student information updated in Firestore.");
      setIsStudentInfoModalOpen(false);
      navigate("/student-dashboard");
    } catch (error) {
      console.error("Error saving student information:", error);
      setError("Failed to save student information. Please try again.");
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const student = result.user;

      // Use displayName from Google sign-in
      const fullName = student.displayName || "";
      const [firstName, ...lastNameParts] = fullName.split(" ");
      const lastName = lastNameParts.join(" ") || "";

      // Save to Firestore
      const studentDocRef = doc(firestore, "students", student.uid);
      const studentDoc = await getDoc(studentDocRef);

      if (!studentDoc.exists()) {
        await setDoc(studentDocRef, {
          email: student.email,
          uid: student.uid,
          firstName: firstName || "",
          lastName: lastName || "",
          studentName: fullName,
          grade: "",
          studentId: "",
          enrolledClasses: [],
          addedBy: "unknown",
        });

        // Allow user to complete additional details
        setStudentDetails({
          firstName,
          lastName,
          grade: "",
          studentId: "",
        });
        setIsStudentInfoModalOpen(true);
      } else {
        console.log("Student already exists in Firestore:", studentDoc.data());
        navigate("/student-dashboard");
      }
    } catch (error) {
      console.error("Error with Google sign-in:", error);
      setError("Google sign-in failed. Please try again.");
    }
  };

  const handleForgotPasswordClick = () => {
    setIsForgotPasswordModalOpen(true);
  };

  const closeForgotPasswordModal = () => {
    setIsForgotPasswordModalOpen(false);
  };

  return (
    <div className="form-container">
      <form className="login-form" onSubmit={handleLogInOrRegister}>
        <div className="form-row">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Email"
          />
        </div>
        <div className="form-row">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Password"
          />
        </div>
        <div className="form-row">
          <button type="submit">Log In / Register</button>
        </div>
        <div className="form-row">
          <button onClick={handleForgotPasswordClick}>Forgot Password?</button>
        </div>
        <div className="form-row">
          <button onClick={handleGoogleSignIn} className="google-login-button">
            <img
              src={googleButton}
              alt="Sign in with Google"
              style={{ width: "200px" }}
            />
          </button>
        </div>

        {error && <div className="error-message">{ERROR_MESSAGE}</div>}
      </form>

      {/* Forgot Password Modal */}
      {isForgotPasswordModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Forgot Password</h2>
            <p>Please contact your teacher to reset your password.</p>
            <button onClick={closeForgotPasswordModal}>Close</button>
          </div>
        </div>
      )}

      {/* Student Info Modal */}
      {isStudentInfoModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Complete Your Registration</h2>
            <input
              type="text"
              value={studentDetails.firstName}
              onChange={(e) =>
                setStudentDetails({
                  ...studentDetails,
                  firstName: e.target.value,
                })
              }
              placeholder="First Name"
              required
            />
            <input
              type="text"
              value={studentDetails.lastName}
              onChange={(e) =>
                setStudentDetails({
                  ...studentDetails,
                  lastName: e.target.value,
                })
              }
              placeholder="Last Name"
              required
            />
            <input
              type="text"
              value={studentDetails.grade}
              onChange={(e) =>
                setStudentDetails({ ...studentDetails, grade: e.target.value })
              }
              placeholder="Grade"
              required
            />

            <input
              type="text"
              value={studentDetails.studentId}
              onChange={(e) =>
                setStudentDetails({
                  ...studentDetails,
                  studentId: e.target.value,
                })
              }
              placeholder="Student ID"
              required
            />
            <div className="modal-buttons">
              <button onClick={closeStudentInfoModal}>Save and Continue</button>
              <button onClick={() => setIsStudentInfoModalOpen(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentLogIn;
