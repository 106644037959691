import React, { useState, useContext } from "react";
import SchoolDropdown from "./SchoolDropdown";
import AddSchoolModal from "./AddSchoolModal";
import { firestore, auth } from "./FirebaseConfig";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const AfterRegistrationSchoolSelection = () => {
  const userUid = auth.currentUser.uid;
  const navigate = useNavigate();

  const [selectedSchool, setSelectedSchool] = useState(null);
  const [showAddSchoolModal, setShowAddSchoolModal] = useState(false);
  const [error, setError] = useState("");

  const handleAddSchool = () => {
    setShowAddSchoolModal(true); // Open the modal to add a new school
  };

  const handleSubmitSchool = async () => {
    if (!selectedSchool || !selectedSchool.id) {
      setError("Please select a school before submitting.");
      return;
    }

    try {
      // Update the user's Firestore document with selected school details
      const userDocRef = doc(firestore, "users", userUid);
      await updateDoc(userDocRef, {
        schoolId: selectedSchool.id,
        schoolName: selectedSchool.schoolName, // Add the school name
      });

      // Update the school's Firestore document with the user's UID
      const schoolDocRef = doc(firestore, "schools", selectedSchool.id);
      await updateDoc(schoolDocRef, {
        teachers: arrayUnion(userUid),
      });

      // Redirect to the dashboard after successful update
      navigate("/dashboard");
    } catch (error) {
      console.error("Error updating Firestore:", error);
      setError("Failed to register your school. Please try again.");
    }
  };

  return (
    <div>
      <h2>Select Your School</h2>

      {/* Error message display */}
      {error && <p style={{ color: "red" }}>{error}</p>}

      {/* School Dropdown */}
      <SchoolDropdown
        onAddSchool={handleAddSchool}
        onSelectSchool={setSelectedSchool} // Pass setSelectedSchool as the callback
      />

      {/* Selected School Details */}
      {selectedSchool && (
        <div>
          <p>You have selected:</p>
          <p>
            <strong>{selectedSchool.schoolName}</strong>
          </p>
          <p>{selectedSchool.address}</p>
          <p>
            {selectedSchool.city}, {selectedSchool.state}{" "}
            {selectedSchool.zipcode}
          </p>

          <button onClick={handleSubmitSchool}>Submit School</button>
        </div>
      )}

      {/* Add School Modal */}
      {showAddSchoolModal && (
        <AddSchoolModal
          onClose={() => setShowAddSchoolModal(false)}
          onSchoolAdded={(newSchoolData) => {
            setSelectedSchool(newSchoolData);
            setShowAddSchoolModal(false);
          }}
        />
      )}
    </div>
  );
};

export default AfterRegistrationSchoolSelection;
