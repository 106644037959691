import React, { useState, useEffect } from "react";
import { firestore, auth } from "./FirebaseConfig";
import { doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const CompleteProfile = () => {
  const user = auth.currentUser;
  const userUid = user?.uid;
  const navigate = useNavigate();

  // State variables
  const [title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [role, setRole] = useState("");
  const [showNameModal, setShowNameModal] = useState(true);
  const [showSchoolModal, setShowSchoolModal] = useState(false);
  const [error, setError] = useState("");

  // Preload first name, last name, and display name from Google
  useEffect(() => {
    if (user?.displayName) {
      const [first, ...lastParts] = user.displayName.split(" ");
      setFirstName(first || "");
      setLastName(lastParts.join(" ") || "");
      setDisplayName(user.displayName);
    }
  }, [user]);

  const handleSubmitProfile = async () => {
    if (!title || !firstName || !lastName || !displayName || !role) {
      setError("All fields are required. Please complete the form.");
      return;
    }

    try {
      // Save profile data to Firestore
      const userDocRef = doc(firestore, "users", userUid);
      await setDoc(
        userDocRef,
        {
          title,
          firstName,
          lastName,
          displayName,
          role,
          email: user.email,
          uid: userUid,
        },
        { merge: true }
      );

      setShowNameModal(false);
      setShowSchoolModal(true); // Show the "Add School" modal
    } catch (error) {
      console.error("Error saving profile data:", error);
      setError("Failed to save profile data. Please try again.");
    }
  };

  const handleGoToSchoolRegistration = () => {
    navigate("/afterregistrationschoolselection"); // Navigate to the school selection page
  };

  const handleSkipSchool = () => {
    navigate("/dashboard"); // Redirect to the dashboard if they skip
  };

  const handleCancel = () => {
    navigate("/dashboard"); // Redirect to the dashboard if they cancel
  };

  return (
    <div>
      {/* Name Confirmation Modal */}
      {showNameModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Complete Your Profile</h2>

            {/* Error message */}
            {error && <p style={{ color: "red" }}>{error}</p>}

            {/* Profile Form */}
            <label>
              Title:
              <select
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              >
                <option value="">Select Title</option>
                <option value="Mr.">Mr.</option>
                <option value="Mrs.">Mrs.</option>
                <option value="Ms.">Ms.</option>
                <option value="Dr.">Dr.</option>
              </select>
            </label>
            <label>
              First Name:
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </label>
            <label>
              Last Name:
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </label>
            <label>
              Display Name:
              <input
                type="text"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                required
              />
            </label>
            <label>
              Role:
              <select
                value={role}
                onChange={(e) => setRole(e.target.value)}
                required
              >
                <option value="">Select Role</option>
                <option value="Teacher">Teacher</option>
                <option value="Administrator">Administrator</option>
                <option value="Student">Student</option>
                <option value="Parent">Parent</option>
              </select>
            </label>

            <div>
              <button onClick={handleSubmitProfile}>Confirm</button>
              <button onClick={handleCancel}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {/* School Addition Modal */}
      {showSchoolModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Add a School to Your Profile</h2>
            <p>Would you like to add a school to your profile now?</p>
            <div>
              <button onClick={handleGoToSchoolRegistration}>Add School</button>
              <button onClick={handleSkipSchool}>Skip</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompleteProfile;
