import React, { useState } from "react";
import {
  collection,
  doc,
  getDoc,
  addDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { firestore } from "./FirebaseConfig";
import useEnrolledStudents from "./useEnrolledStudents";
import { useUser } from "./UserContext";
import "./SignOutForm.css";

const SignOutForm = () => {
  const [idNumber, setIdNumber] = useState("");
  const [location, setLocation] = useState("");
  const [customLocation, setCustomLocation] = useState("");
  const { enrolledStudentsMap } = useEnrolledStudents();
  const { user } = useUser();

  const classroomName = user?.displayName
    ? `${user.displayName}'s Classroom`
    : "Classroom";

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
    if (e.target.value !== "Other") setCustomLocation("");
  };

  const searchStudent = async (idNumber) => {
    try {
      if (!idNumber.trim()) {
        console.error("ID Number cannot be empty.");
        return null;
      }

      const studentsRef = collection(firestore, "students");

      // Check if the student was added by the current teacher
      const queryByAddedBy = query(
        studentsRef,
        where("studentId", "==", idNumber),
        where("addedBy", "==", user.uid)
      );
      const addedBySnapshot = await getDocs(queryByAddedBy);

      if (!addedBySnapshot.empty) return addedBySnapshot.docs[0].id;

      // Check if the teacher's UID is listed in the 'teachers' array
      const queryByTeachersArray = query(
        studentsRef,
        where("studentId", "==", idNumber),
        where("teachers", "array-contains", user.uid)
      );
      const teachersArraySnapshot = await getDocs(queryByTeachersArray);

      if (!teachersArraySnapshot.empty) return teachersArraySnapshot.docs[0].id;

      return null;
    } catch (error) {
      console.error("Error searching for student:", error);
      return null;
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const foundStudentId = await searchStudent(idNumber);
      if (!foundStudentId) {
        console.error("Student not found.");
        return;
      }

      const isEnrolled = enrolledStudentsMap[foundStudentId];
      if (!isEnrolled) {
        console.error("Student is not enrolled in your classes.");
        return;
      }

      const studentDocRef = doc(firestore, "students", foundStudentId);
      const studentData = (await getDoc(studentDocRef)).data();

      if (!studentData) {
        console.error("Student data not found.");
        return;
      }

      const signOutData = {
        studentDocumentNumber: foundStudentId,
        studentName: studentData.studentName,
        idNumber,
        grade: studentData.grade,
        schoolId: studentData.schoolId,
        teacherUID: user.uid,
        time_out: new Date().toISOString(),
        time_in: null,
        location: location === "Other" ? customLocation : location,
        classId: studentData.enrolledClasses
          ? studentData.enrolledClasses[0]
          : null,
      };

      const classRef = doc(firestore, "classes", signOutData.classId);
      const classSignOutRef = collection(classRef, "signouts");
      await addDoc(classSignOutRef, signOutData);

      setIdNumber("");
      setLocation("");
      setCustomLocation("");
    } catch (error) {
      console.error("Error adding sign-out data:", error);
    }
  };

  return (
    <div className="form-container">
      <h2>{classroomName}</h2>
      <form className="form" onSubmit={handleFormSubmit}>
        <div className="form-row-input">
          <input
            type="text"
            placeholder="ID Number"
            value={idNumber}
            onChange={(e) => setIdNumber(e.target.value)}
            required
          />
          <select value={location} onChange={handleLocationChange} required>
            <option value="">Select Location</option>
            <option value="Bathroom">Bathroom</option>
            <option value="Water Fountain">Water Fountain</option>
            <option value="Nurse">Nurse</option>
            <option value="Other">Other</option>
          </select>
        </div>

        {location === "Other" && (
          <div className="custom-location-input">
            <input
              type="text"
              placeholder="Enter custom location"
              value={customLocation}
              onChange={(e) => setCustomLocation(e.target.value)}
              required
            />
          </div>
        )}

        <button type="submit">Sign Out</button>
      </form>
    </div>
  );
};

export default SignOutForm;
